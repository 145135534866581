import React from 'react'

import { InlineLabel, Select } from '@te-digi/styleguide'

import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { Section } from '../../components/Section'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="InlineLabel"
    components={[{ component: InlineLabel }]}
  >
    <Section>
      <Playground>
        <InlineLabel
          htmlFor="inline-label-example-select"
          label="Lorem ipsum"
        >
          <Select id="inline-label-example-select" />
        </InlineLabel>
      </Playground>
      <Playground format="html">
        <div className="inline-label">
          <label
            className="label-heading"
            htmlFor="inline-label-input-example"
          >
            Label
          </label>
          <div>
            <input
              id="inline-label-input-example"
              className="form-control"
              type="text"
            />
          </div>
        </div>
      </Playground>
    </Section>
  </Content>
)

export default Page
